@import url(https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.modalBackground {
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 0, 0, 0.2);;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    z-index: 999;
  }
 
  
  .modalContainer {
    width: auto;
    height: auto;
    min-width: 40vw;
    min-height: 40vh;
    overflow-y: scroll;
    overflow-x: scroll;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    /* display: flex; */
    /* flex-direction: column; */
    padding: 25px;
    z-index: 99999;
  /* background-repeat: no-repeat;
  background-size: cover;
   background-image: url("https://firebasestorage.googleapis.com/v0/b/bharatkojanofinale.appspot.com/o/popup_background.png?alt=media&token=7278c2ea-3749-446a-a10d-0aae9733a9cc"); */

  }
  
  .modalContainer .title {
    display: inline-block;
    text-align: center;
    margin-top: 10px;
  
  }
  
  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
  }
  
  .titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
    position: fixed;
    padding: 5px;
    border: 1px solid black;
  }
  
  .modalContainer .body {
    /* flex: 50%; */
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    font-size: 1rem;
    line-height: 1.9rem;
    /* text-align: center; */
  }
  
  .modalContainer .footer {
    flex: 20% 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContainer .footer button {
    width: 150px;
    height: 45px;
    margin: 10px;
    border: none;
    background-color: cornflowerblue;
    color: white;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
  }
  
  #cancelBtn {
    background-color: crimson;
  }

  .modalContainer::-webkit-scrollbar {
    display: none;
}

.modal-content{
  width: 200% !important;
}
h1{color: #607d8b;
    font-weight: 700;
    font-size: 2.3em;
    text-transform: uppercase;
    margin-bottom: 20px;
    border-bottom: 4px solid #2a3a8d;
    display: inline-block;
    letter-spacing: 1px;
    }
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

section{
    position: relative;
    width: 100%;
    height:100vh;
    display: flex;
}

section .imgBx{
    position: relative;
    width: 50%;
    height: 100;
    /* background-image: url('https://firebasestorage.googleapis.com/v0/b/rsvm-dhanbad.appspot.com/o/logo_02_FN.png?alt=media&token=f9092412-1312-4ee9-af31-fe59b5242dd8');
    background-repeat: no-repeat;
; */
}
/* section .imgBx:before{
    content: "";
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background: linear-gradient(225deg,#e91e63,#03a9f4);
    z-index: 1;
    mix-blend-mode: screen;
} */
section .imgBx .carousel{
    height: 100%;
}
section .imgBx .carousel img{
    /* position: absolute;
    top: 0;
    left: 0; */
    width: 100%;
    height: 100vh;
    object-fit: fill;  
    z-index: 999;  
}

section .contentBx{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;

}

section .contentBx .formBx{
    width: 50%;
}

section .contentBx .formBx h2{
    color: #607d8b;
    font-weight: 600;
    font-size: 1.5em;
    text-transform: uppercase;
    margin-bottom: 20px;
    border-bottom: 4px solid #006ba1;
    display: inline-block;
    letter-spacing: 1px;
}

section .contentBx .formBx .inputBx{
    margin-bottom: 20px;
}

section .contentBx .formBx .inputBx span{
    font-size: 16px;
    margin-bottom: 5px;
    display: inline-block;
    color: #607d8b;
    font-weight: 300;
    letter-spacing: 1px;
}

section .contentBx .formBx .inputBx input{
 width: 100%;
 padding: 10px 20px;
 outline: none;
 font-weight: 400;
 border: 1px solid #607d8b;
 font-size: 16px;
 letter-spacing: 1px;
 color: #607d8b;
 background: transparent;
 border-radius: 30px;
}

section .contentBx .formBx .inputBx input[type="submit"]{
    background:#006ba1 ;
    color:#fff;
    outline:none;
    border:none;
    cursor: pointer;
    font-weight: 500;
}


section .contentBx .formBx .inputBx button{
    background:#006ba1 ;
    color:#fff;
    padding: 15px;
   
    justify-content: center;
    border: 1px solid #f4f9fc;
    cursor: pointer;
   
    font-weight: 500;
    text-align: center;
}

section .contentBx .formBx .inputBx input[type="submit"]:hover{
    background: #f53677;
}

section .contentBx .formBx .remember{
    margin-bottom: 10px;
    color:#607d8b !important;
    font-weight: 400;
    font-size: 14px;
}


section .contentBx .formBx .inputBx p{
    color: #607d8b;
}


section .contentBx .formBx .inputBx p a{
    color:#006ba1;
}

@media (max-width: 768px){
    section .imgBx{
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;

    }
    section .contentBx{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        z-index:1;
    }

    section .contentBx .formBx{
        width: 100%;
        padding: 40px;
        background:  rgb(255 255 255 / 0.9);
        margin: 50px
    }
}

.desktopview{
    display: block;
}

.buttons{
    background:rgb(235,86,57) ;
    color:#fff;
    outline:none;
    border:none;
    cursor: pointer;
    font-weight: 500;
    justify-content: center;
}
.correctbuttons{
    background: green ;
    color:#fff;
    outline:none;
    border:none;
    cursor: not-allowed;
    font-weight: 500;
    justify-content: center;
}
.incorrectbuttons{
    background: red;
    color:#fff;
    outline:none;
    border:none;
    cursor: not-allowed;
    font-weight: 500;
    justify-content: center;
}
.incorrectbuttons:hover{
    background: red !important;
    color:#fff;
    outline:none;
    border:none;
    cursor: not-allowed;
    font-weight: 500;
    justify-content: center;
}
.correctbuttons:hover{
    background: green !important;
    color:#fff;
    outline:none;
    border:none;
    cursor: not-allowed;
    font-weight: 500;
    justify-content: center;
}
.buttons:disabled{
    background: grey !important;
    cursor: not-allowed;
}
.mobileview{
    display: none;
}
@media (max-width: 768px){
    .mobileview{
        display: block;
    }

    .desktopview{
        display: none;
    }
}

