@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

section{
    position: relative;
    width: 100%;
    height:100vh;
    display: flex;
}

section .imgBx{
    position: relative;
    width: 50%;
    height: 100;
    /* background-image: url('https://firebasestorage.googleapis.com/v0/b/rsvm-dhanbad.appspot.com/o/logo_02_FN.png?alt=media&token=f9092412-1312-4ee9-af31-fe59b5242dd8');
    background-repeat: no-repeat;
; */
}
/* section .imgBx:before{
    content: "";
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background: linear-gradient(225deg,#e91e63,#03a9f4);
    z-index: 1;
    mix-blend-mode: screen;
} */
section .imgBx .carousel{
    height: 100%;
}
section .imgBx .carousel img{
    /* position: absolute;
    top: 0;
    left: 0; */
    width: 100%;
    height: 100vh;
    object-fit: fill;  
    z-index: 999;  
}

section .contentBx{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;

}

section .contentBx .formBx{
    width: 50%;
}

section .contentBx .formBx h2{
    color: #607d8b;
    font-weight: 600;
    font-size: 1.5em;
    text-transform: uppercase;
    margin-bottom: 20px;
    border-bottom: 4px solid #006ba1;
    display: inline-block;
    letter-spacing: 1px;
}

section .contentBx .formBx .inputBx{
    margin-bottom: 20px;
}

section .contentBx .formBx .inputBx span{
    font-size: 16px;
    margin-bottom: 5px;
    display: inline-block;
    color: #607d8b;
    font-weight: 300;
    letter-spacing: 1px;
}

section .contentBx .formBx .inputBx input{
 width: 100%;
 padding: 10px 20px;
 outline: none;
 font-weight: 400;
 border: 1px solid #607d8b;
 font-size: 16px;
 letter-spacing: 1px;
 color: #607d8b;
 background: transparent;
 border-radius: 30px;
}

section .contentBx .formBx .inputBx input[type="submit"]{
    background:#006ba1 ;
    color:#fff;
    outline:none;
    border:none;
    cursor: pointer;
    font-weight: 500;
}


section .contentBx .formBx .inputBx button{
    background:#006ba1 ;
    color:#fff;
    padding: 15px;
   
    justify-content: center;
    border: 1px solid #f4f9fc;
    cursor: pointer;
   
    font-weight: 500;
    text-align: center;
}

section .contentBx .formBx .inputBx input[type="submit"]:hover{
    background: #f53677;
}

section .contentBx .formBx .remember{
    margin-bottom: 10px;
    color:#607d8b !important;
    font-weight: 400;
    font-size: 14px;
}


section .contentBx .formBx .inputBx p{
    color: #607d8b;
}


section .contentBx .formBx .inputBx p a{
    color:#006ba1;
}

@media (max-width: 768px){
    section .imgBx{
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;

    }
    section .contentBx{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        z-index:1;
    }

    section .contentBx .formBx{
        width: 100%;
        padding: 40px;
        background:  rgb(255 255 255 / 0.9);
        margin: 50px
    }
}